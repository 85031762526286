// Primary colors
$red-poppy: #f8485e;
$red-poppy-dark: #ef384e;
$dark-grey: #3c3c3a;
$white: #ffffff;
//Secondary colors
$light-grey: #efeeee;
//Accent colors
$candy-light: #feedef;
$black: #000000;
$text-1: #333;
$text-2: #666;
$text-3: #999;
$time-bg: #eee;
$background: #f7f7f7;
$cv-background: #7a8599;
$cv-bubble-background:rgb(239, 234, 220);
