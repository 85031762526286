.wpml-ls-legacy-dropdown-click {
  width: 15.5em;
  max-width: 100%;
}

.wpml-ls-legacy-dropdown-click > ul {
  position: relative;
  padding: 0;
  margin: 0 !important;
  list-style-type: none;
}

.wpml-ls-legacy-dropdown-click .wpml-ls-item {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.wpml-ls-legacy-dropdown-click a {
  display: block;
  text-decoration: none;
  color: #444;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  padding: 5px 10px;
  line-height: 1;
}

.wpml-ls-legacy-dropdown-click a.wpml-ls-item-toggle {
  position: relative;
  padding-right: calc(10px + 1.4em);
}

.wpml-ls-legacy-dropdown-click a.wpml-ls-item-toggle:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  border: 0.35em solid transparent;
  border-top: 0.5em solid;
  position: absolute;
  right: 10px;
  top: calc(50% - 0.175em);
}

.wpml-ls-legacy-dropdown-click .wpml-ls-current-language:hover > a,
.wpml-ls-legacy-dropdown-click a:focus,
.wpml-ls-legacy-dropdown-click a:hover {
  color: #000;
  background: #eee;
}

.wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu {
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  border-top: 1px solid #cdcdcd;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 101;
}

.wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu a {
  border-width: 0 1px 1px;
}
